body {
    font-family: "Figtree", sans-serif;
    font-optical-sizing: auto;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
}
header {
    color: white;
    padding: 10px 0;
    text-align: center;
}
main {
    max-width: 800px;
    margin: 0px auto;
    padding: 1rem 2rem;
    background-color: white;
    border-radius: 1rem;
}


@media only screen and (max-device-width: 768px) {
    .privacy-policy h1 {
        margin: 1rem 0 !important;
    }

    main {
        margin:0  1rem;
    }
}
.privacy-policy h1, .privacy-policy h2 {
    color: #333;
}
.privacy-policy h1 {
    font-size: 3em;
    margin: 1rem;
}
p {
    margin-bottom: 15px;
}
ul {
    list-style-type: disc;
    margin-left: 20px;
}
footer {
    text-align: center;
    padding: 10px 0;
    background-color: #FF7979FF;
    color: white;
    margin-top: 1rem;
}