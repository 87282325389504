body {
}

.background-pattern-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
    background: rgb(255,121,121);
    background: linear-gradient(0deg, rgba(29,209,161,1) 0%, rgba(255,121,121,1) 100%);
    min-height: 100vh;
}

.background-pattern {
    --expand-amount: -700px;
    left: var(--expand-amount);
    top: var(--expand-amount);
    bottom: var(--expand-amount);
    right: var(--expand-amount);
    background-image: url("/public/assets/bg-pattern.png");
    transform: rotate(-15deg);
    opacity: 0.1;
    z-index: -1;
    position: absolute;
}

.row-1 {
    /*background: rgba(0,0,0,0.2);*/
    padding: 2rem 0;
}

.container {
    max-width: 960px;
    margin: 0 auto;
    z-index: 2;
}

@media only screen and (max-device-width: 768px) {
    h1 {
        margin-top: 3rem !important;
        font-size: 3em !important;
    }

    .login-section {
        padding: 1rem 0 !important;
    }

    .login-screenshots-container {
        display: flex;
        justify-content: center;
        flex: none !important;
    }

    .app-login-screenshot {
        max-height: 300px !important;
        margin: 0 1rem;
    }
}

.app-description {
    font-size: 1.6em;
    font-weight: 500;
    color: white;
}

.app-description h1 {
    color: white !important;
    font-size: 2em;
    font-weight: 800;
    margin-top: 0;
}

.app-description-screenshot {
    max-width: 320px;
}

.app-login-screenshot {
    max-width: 240px;
}

.login-screenshots-container {
    flex: 0 560px;
}

.login-detail-info a {
    color: inherit;
}

.login-section {
    padding: 3rem 0;
}

.card {
    background: white;
    border-radius: 1rem;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
}

.card i {
    font-size: 4em;
}

h2 {
    font-size: 1.6em;
}

.card svg {
    height: 100px;
    margin: 20px 0;
}

.card i {
    /*height: 100px;*/
    font-size: 120px;
    margin-right: -12px;
}

footer a {
    text-decoration: none;
    display: inline-block;
    margin: .5rem 1rem;
    font-weight: bold;
    color: white;
}
